<template>
  <div class="main">
    <el-form :label-position="'left'" label-width="80px" style="margin: 20px; width: 600px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="手机号">
            <el-input v-model="queryPhone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="时间区间">
            <el-date-picker v-model="startDay" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-time-select
              placeholder="选择时间"
              v-model="startTime"
              :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00',
              }"
            >
            </el-time-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item>
            <el-date-picker v-model="endDay" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-time-select
              placeholder="选择时间"
              v-model="endTime"
              :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00',
              }"
            >
            </el-time-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="button-row">
        <el-form-item>
          <el-button type="primary" @click="onQuery">查询</el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <div class="table">
      <el-table :data="tableData" highlight-current-row>
        <el-table-column label="id" width="200">
          <template slot-scope="scope">
            <div v-html="scope.row.id"></div>
          </template>
        </el-table-column>
        <el-table-column label="用户id" width="200">
          <template slot-scope="scope">
            <div v-html="scope.row.userId"></div>
          </template>
        </el-table-column>
        <el-table-column label="标题" width="200">
          <template slot-scope="scope">
            <div v-html="scope.row.title"></div>
          </template>
        </el-table-column>
        <el-table-column label="内容">
          <template slot-scope="scope">
            <div v-html="scope.row.planText"></div>
          </template>
        </el-table-column>
        <el-table-column label="是否完成" width="100">
          <template slot-scope="scope">
            <div v-html="scope.row.hasFinish"></div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="small" @click="onView(scope.$index, scope.row)">浏览</el-button>
            <el-button size="small" @click="onEdit(scope.$index, scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import msgLogger from "@/util/msgLogger";
import { userLearnPlanApi } from "@/api/userLearnPlanApi";
import { strToEpoch } from "@/util/timeTool.js";
import { toNumber } from "@/util/numberUtil.js";

export default {
  components: {},
  data() {
    return {
      aidaUser: null,
      queryPhone: "",

      queryCourseId: "",
      courseName: "",

      startDay: "",
      startTime: "",
      endDay: "",
      endTime: "",

      userLearnPlan: {
        id: 0,
        userId: 0,
        title: "",
        planText: "",
        startTime: 0,
        endTime: 0,
        hashFinish: 0,
        attach: [],
      },

      tableData: [],
    };
  },
  methods: {
    async onQuery() {
      let [err, ret] = await this.awaitWrap(userLearnPlanApi.getAidaUser(this.queryPhone));
      console.log(err, ret, "debug...wrap...");
      if (err) {
        msgLogger.error("系统出错");
      }
      if (ret.code == 0) {
        this.aidaUser = ret.data;
      } else {
        return;
      }
      let startStr = this.startDay + " " + this.startTime;
      let startTime = strToEpoch(startStr);
      let endStr = this.endDay + " " + this.endTime;
      let endTime = strToEpoch(endStr);
      console.log("time", startTime, endTime);
      [err, ret] = await this.awaitWrap(
        userLearnPlanApi.queryUserLearnPlan(this.aidaUser.userId, toNumber(this.queryCourseId, 0), startTime, endTime)
      );
      if (ret && ret.code == 0) {
        this.tableData = ret.data;
        console.log(this.tableData);
      } else {
        msgLogger.error("系统出错");
      }
    },
    onView(index, row) {
      let path = `/user-plan/view?id=${row.id}`;
      let routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },
    onEdit(index, row) {
      let path = `/user-plan/add-edit?id=${row.id}`;
      let routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },
    awaitWrap(promise) {
      return promise.then((data) => [null, data]).catch((err) => [err, null]);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  .box-card {
    text-align: left;
    margin: 20px 0px;
  }
  .button-row {
    text-align: right;
    margin: 20px;
  }
  .table {
    width: 90%;
  }
}
</style>
